import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';
import * as R    from 'ramda';

import EditIcon                from '@material-ui/icons/ModeEdit';
import ConditionalQuestionIcon from '@material-ui/icons/Copyright';
import InternalQuestionIcon    from '@material-ui/icons/InfoOutline';
import WarningIcon             from '@material-ui/icons/Warning';
import Logo                    from '../../../../components/Logo';
import { Button }              from '@material-ui/core';
import DragAndDropPhoto        from './DragAndDropPhoto';

import {
  StarBorder,
  Flag
} from '@material-ui/icons';

import parseAnswerTitle                       from './utils/parseAnswerTitle';

//import { makeDecoratedImageUrl, getRotation } from '../../../../utils/image';
import { makeDecoratedThumbnailImageUrl , getRotation } from '../../../../utils/image';

import "video-react/dist/video-react.css"; // import css
import { BigPlayButton, ControlBar, Player, VolumeMenuButton } from 'video-react';

const QuestionAnswerWrapper = styled.div`
  display     : ${({ isClientUser, isInternalQuestion }) => isClientUser && isInternalQuestion ? 'none' : 'block'};
  margin-left : ${props => props.level * 1 - 1}rem;
`;

const Wrapper = styled.div`
  display          : flex;
  align-items      : center;
  font-size        : .75rem;
  font-weight      : 500;
  color            : #909091;
  margin           : .5rem 0 0 0;
  padding          : .5rem 1rem .5rem .5rem;
  border-bottom    : 2px solid #EEEEEE;
  background-color : #F4F4F4;
  opacity          : ${({hasAnswer, hasOptions}) => hasAnswer || hasOptions ? '1' : '.4'};
`;

const AnswerTitle = styled.div`
  display      : flex;
  align-items  : center;
  height       : auto;
  margin       : .5rem 0 0 0;
  padding      : 0;
  font-size    : .875rem;
  font-style   : oblique;
  color        : #767676;
  font-weight  : 500;
`;

const QuestionAnswerRow = styled.div`
  display       : flex;
  align-items   : center;
  width         : 100%;
  border-bottom : 2px solid #EEEEEE;

  svg {
    width  : 5%;
    cursor : pointer;
  }

  .EditIcon {
    visibility  : hidden;
    font-size   : 1.5rem;
    height      : 1em;
    width       : 1em;
    margin-left : .25rem;
  }

  ${({ isClientUser }) => !isClientUser && '&:hover { .EditIcon { visibility: visible } };'}

  ${({ reviewMode }) => reviewMode && '&:hover { svg { display: inline-block; } };'}

  div {
    width : 50%;

    svg {
      display : block;
    }
  }

  p {
    width       : 45%;
    padding-top : .5rem;
    margin      : 0 0 0 .5rem;
    font-size   : .875rem;
  }

  .VideoControl {
    height      : 1em;
    width       : 10px;
    max-width   : 10px;
  }

  .PlayButton {
  }
`;

const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({isEditingPhotos}) => isEditingPhotos ? "width: 100%!important;" : ''}
`;

const Thumbnail = styled.img`
  height      : auto;
  width       : 135px;
  cursor      : ${({isEditingPhotos}) => isEditingPhotos ? 'grab' : 'pointer'};
  margin-left : 10px;
  align-self  : center;
`;

const MotiveInformation = styled.div`
  display: flex;
  border: solid 1px #ebebeb;
  width: 100% !important;
  font-size: 0.9rem;
  div {
    width: 100%;
    box-sizing: border-box;
  }
`;

const MotiveInformationHead = styled.div`
  display: flex;
  justify-content: center;
`;

const MotiveInformationColumn = styled.div`
  display: flex;
  flex-direction: column;  
`;

const VideoThumbnail = styled.div`
  display         : flex;
  flex-direction  : column;
  cursor          : pointer;
  margin-left     : 10px;
  align-items     : center;
  justify-content : center;

  .video-react-video, .video-react {
    background-color  : white;
  }
`;

const logoStyle = {
  width     : '90%',
  maxHeight : 'initial',
  margin    : 'auto'
};

const renderLogo = () => (
  <div style={{  }}>
    <div style={{ width : '150px', textAlign : 'center', backgroundColor : '#000' }}>
      <Logo style={logoStyle}/>
    </div>
  </div>
);

const maybeDisplayThumbnail = ({
  question,
  currentAnswer,
  photos,
  signatures,
  onThumbnailClick,
  blockPhotos,
  videos,
  onVideoThumbnailClick,
  blockVideos,
  rotateVideos,
  isClientUser,
  starred,
  flagged,
  isEditingPhotos,
  onSwitchPhotos,
  onDeletePhotoClick,
  cReview
}) => {
  if (question.type === 'photo-input' || question.type === 'signature-input') {
    return maybeDisplayPhotoThumbnail({
      question,
      currentAnswer,
      photos,
      signatures,
      onThumbnailClick,
      blockPhotos,
      isClientUser,
      starred,
      flagged,
      isEditingPhotos,
      onSwitchPhotos,
      onDeletePhotoClick,
      cReview
    });
  } else if (question.type === 'video-input') {
    return maybeDisplayVideoThumbnail({
      question,
      currentAnswer,
      videos,
      onVideoThumbnailClick,
      blockVideos,
      rotateVideos,
      isClientUser
    });
  } else {
    return <p>{parseAnswerTitle(question.type, currentAnswer, question.options)}</p>;
  }
};

const maybeDisplayPhotoThumbnail = ({
  question,
  currentAnswer,
  photos,
  signatures,
  onThumbnailClick,
  blockPhotos,
  isClientUser,
  starred,
  flagged,
  isEditingPhotos,
  onSwitchPhotos,
  onDeletePhotoClick,
  cReview
}) => {
  if (question.type === 'photo-input' || question.type === 'signature-input') {

    const currentPhoto = R.compose(
      R.defaultTo({ url : '' , xsurl : '' }),
      R.find(R.propEq('uuid', currentAnswer))
    )(question.type === 'photo-input' ? photos : signatures);

    if (R.isEmpty(currentPhoto.url)) {
      return isEditingPhotos && question.type !== 'signature-input'
      ? <DragAndDropPhoto 
          currentAnswer       ={currentAnswer}
          isEditingPhotos     ={isEditingPhotos}
          onDeletePhotoClick  ={onDeletePhotoClick}
          onSwitchPhotos      ={onSwitchPhotos}
          photos              ={photos}
          question            ={question}>
          {renderLogo()}
      </DragAndDropPhoto>
       : cReview === 'Approved' ? null : renderLogo();
    }

    const isBlockedPhoto = !R.isEmpty(currentAnswer) && isClientUser && !R.isNil(R.find(R.eqProps('uuid', currentPhoto))(blockPhotos));

    const photoComponent = !isBlockedPhoto ? (
      <PhotoContainer isEditingPhotos={isEditingPhotos}>
         <Thumbnail
          src             = {makeDecoratedThumbnailImageUrl(getRotation(currentPhoto.url), 200)(currentPhoto.url,currentPhoto.xsurl)}
          onClick         = {() => !isEditingPhotos && onThumbnailClick(currentPhoto)}
          isEditingPhotos = {isEditingPhotos}
        />
        {(starred || flagged) && !isClientUser
          ? (
              <MotiveInformation>
                {starred 
                  ? <MotiveInformationColumn style={{ borderRight: "solid 1px #ebebeb" }}>
                      <MotiveInformationHead>
                        <StarBorder
                          name    = "StarBorder"
                          style   = {{
                            color: 'red',
                            minWidth: '4rem'
                          }}
                        />
                      </MotiveInformationHead>
                      <div style={{ padding: "10px" }}>{starred.motive}</div>
                    </MotiveInformationColumn> 
                  : null}
                {flagged
                  ? <MotiveInformationColumn style={{ width: "100%" }}>
                      <MotiveInformationHead>
                        <Flag
                          name    = "Flag"
                          style   = {{
                            color: 'red',
                            minWidth: '4rem'
                          }}
                        />
                      </MotiveInformationHead>
                      <div style={{ padding: "10px" }}>{flagged.motive}</div>
                    </MotiveInformationColumn>
                  : null}
              </MotiveInformation>
          )
          : null}
      </PhotoContainer>
    ) : renderLogo();
    
    return isEditingPhotos && question.type !== 'signature-input'
      ? <DragAndDropPhoto 
        currentAnswer       ={currentAnswer}
        isEditingPhotos     ={isEditingPhotos}
        onDeletePhotoClick  ={onDeletePhotoClick}
        onSwitchPhotos      ={onSwitchPhotos}
        photos              ={photos}
        question            ={question}>
        {photoComponent}
      </DragAndDropPhoto>
      : photoComponent;

  } else {
    return <p>{parseAnswerTitle(question.type, currentAnswer, question.options)}</p>;
  }
};

const updateRotation = (video, rotation) => {
  if(!video) {
    return;
  }

  const thisVideo: HTMLVideoElement = R.path(['video', 'video'], video);
  if(!thisVideo) {
    return;
  }
  thisVideo.style.transform = `rotate(${rotation}deg)`;
};

const maybeDisplayVideoThumbnail = ({
  question,
  currentAnswer,
  videos,
  onVideoThumbnailClick,
  blockVideos,
  isClientUser,
  rotateVideos
}) => {
  if (question.type === 'video-input') {

    const currentVideo = R.compose(
      R.defaultTo({ url : '' }),
      R.find(R.propEq('uuid', currentAnswer))
    )(videos);

    if (R.isEmpty(currentVideo.url)) {
      return renderLogo();
    }

    const isBlockedVideo = !R.isEmpty(currentAnswer) && isClientUser && !R.isNil(R.find(R.eqProps('uuid', currentVideo))(blockVideos));
    const rotation = R.compose(R.defaultTo(0), R.prop('rotate'), R.find(R.eqProps('uuid', currentVideo)))(rotateVideos);

    return !isBlockedVideo ? (
      <VideoThumbnail>
        <Button
          color="secondary"
          style={{ padding: '0 30px', width: '100%' }}
          onClick={() => onVideoThumbnailClick(currentVideo)}
          className='no-print'>
            REVIEW VIDEOS
        </Button>
        <Player
          fluid = {false}
          width = {230}
          crossOrigin={"Anonymous"}
          src   = {`${currentVideo.url}`}
          ref={(video => {
            updateRotation(video, rotation);
          })}
        >
          <BigPlayButton position="center" className="PlayButton"/>
          <ControlBar autoHide={false}>
            <VolumeMenuButton vertical />
          </ControlBar>
        </Player>
      </VideoThumbnail>
    ) : renderLogo();

  } else {
    return <p>{parseAnswerTitle(question.type, currentAnswer, question.options)}</p>;
  }
};

const QuestionItem = ({
  answerTitle,
  level,
  options,
  title,
  currentAnswer,
  formAnswers,
  photos,
  signatures,
  videos,
  reviewMode,
  isInternalQuestion,
  isConditional,
  onOpenAnswerFeedbackModal,
  question,
  isRejected,
  rejectedQuestions,
  onThumbnailClick,
  onVideoThumbnailClick,
  isClientUser,
  blockPhotos,
  blockVideos,
  rotateVideos,
  isEditingPhotos,
  onSwitchPhotos,
  svrResponse,
  onDeletePhotoClick,
  cReview,
  iBlockedPhoto,
  iContentlength,
  unAnsweredQuestionlength,
  quickReviewMode
}) => (
  question.type === "photo-input" && cReview === "Approved" && !R.isEmpty(parseAnswerTitle(question.type, currentAnswer)) === false ?
  null :  (iContentlength === unAnsweredQuestionlength || R.isNil(currentAnswer) || R.isEmpty(currentAnswer)) && window.localStorage.getItem("displayUnansweredSections") === 'NO' && !quickReviewMode ? null :
  (iBlockedPhoto &&  isClientUser ) ? null
  :
  <QuestionAnswerWrapper
    level              = {level}
    isClientUser       = {isClientUser}
    isInternalQuestion = {isInternalQuestion}
  >
    {answerTitle && <AnswerTitle level={level}>{`If "${answerTitle}"`}</AnswerTitle>}
    {
      !R.isEmpty(currentAnswer) && isClientUser && !R.isNil(R.find(R.eqProps('uuid'
      , R.compose(
        R.defaultTo({ url: '', xsurl: '' }),
        R.find(R.propEq('uuid', currentAnswer))
      )(question.type === 'photo-input' ? photos : signatures)))(blockPhotos)) ?
      null :
    <QuestionAnswerRow
      reviewMode   = {reviewMode}
      isClientUser = {isClientUser}
    >
      <Wrapper
        level      = {level}
        hasAnswer  = {!R.isEmpty(parseAnswerTitle(question.type, currentAnswer))}
        hasOptions = {!R.isEmpty(options.filter(i => !R.isEmpty(R.prop('conditionalFork', i))))}
      >
        {title}
        {isConditional      && <ConditionalQuestionIcon />}
        {isInternalQuestion && <InternalQuestionIcon />}
      </Wrapper>
      {maybeDisplayThumbnail({
        currentAnswer,
        question,
        photos,
        signatures,
        onThumbnailClick,
        blockPhotos,
        videos,
        onVideoThumbnailClick,
        blockVideos,
        rotateVideos,
        isClientUser,
        flagged: R.find(s => s.uuid === currentAnswer, R.pathOr([], ['metaJson', 'flagPhotos'], svrResponse)),
        starred: R.find(s => s.uuid === currentAnswer, R.pathOr([], ['metaJson', 'starPhotos'], svrResponse)),
        isEditingPhotos,
        onSwitchPhotos,
        onDeletePhotoClick,
        cReview
      })}
      {!isClientUser && isRejected && <WarningIcon style={{ display: 'block', color: '#CB2532' }} />}
      {reviewMode && <EditIcon className="EditIcon" onClick={() => onOpenAnswerFeedbackModal(question)}/>}
    </QuestionAnswerRow>
    }
    {R.flatten(options.map(option => option.conditionalFork
      .filter(q => !isClientUser || !R.pathEq(['meta', 'isHidden'], true, q))
      .map((forkedQuestion, i) => (
      <QuestionItem
        level                     = {R.inc(level)}
        key                       = {forkedQuestion.uuid}
        title                     = {forkedQuestion.title}
        conditionalItems          = {forkedQuestion.options}
        options                   = {forkedQuestion.options}
        answerTitle               = {i === 0 ? option.label : ''}
        formAnswers               = {formAnswers}
        photos                    = {photos}
        videos                    = {videos}
        currentAnswer             = {formAnswers[forkedQuestion.uuid] && formAnswers[forkedQuestion.uuid].answerValue}
        reviewMode                = {reviewMode}
        question                  = {forkedQuestion}
        onOpenAnswerFeedbackModal = {onOpenAnswerFeedbackModal}
        rejectedQuestions         = {rejectedQuestions}
        isRejected                = {R.has(forkedQuestion.uuid, rejectedQuestions)}
        onThumbnailClick          = {onThumbnailClick}
        onVideoThumbnailClick     = {onVideoThumbnailClick}
        isInternalQuestion        = {forkedQuestion.meta.isHidden}
        isConditional             = {forkedQuestion.meta.isConditional}
        isClientUser              = {isClientUser}
        blockPhotos               = {blockPhotos}
        blockVideos               = {blockVideos}
        rotateVideos              = {rotateVideos}
        isEditingPhotos           = {isEditingPhotos}
        onSwitchPhotos            = {onSwitchPhotos}
        onDeletePhotoClick        = {onDeletePhotoClick}
        cReview                   = {cReview}
        iBlockedPhoto             = {iBlockedPhoto}
        iContentlength            = {iContentlength}
        unAnsweredQuestionlength  = {unAnsweredQuestionlength} 
      />
    ))))}
  </QuestionAnswerWrapper>
);

QuestionItem.propTypes = {
  answerTitle               : PropTypes.string,
  questionUuid              : PropTypes.string,
  level                     : PropTypes.number,
  options                   : PropTypes.array,
  title                     : PropTypes.string,
  currentAnswer             : PropTypes.string,
  formAnswers               : PropTypes.object,
  photos                    : PropTypes.array,
  signatures                : PropTypes.array,
  videos                    : PropTypes.array,
  reviewMode                : PropTypes.bool,
  isConditional             : PropTypes.bool,
  isInternalQuestion        : PropTypes.bool,
  onOpenAnswerFeedbackModal : PropTypes.func,
  onThumbnailClick          : PropTypes.func,
  onVideoThumbnailClick     : PropTypes.func,
  isRejected                : PropTypes.bool,
  rejectedQuestions         : PropTypes.object,
  isClientUser              : PropTypes.bool,
  blockPhotos               : PropTypes.array,
  blockVideos               : PropTypes.array,
  flagged                   : PropTypes.object,
  starred                   : PropTypes.object
};

QuestionItem.defaultProps = {
  answerTitle               : '',
  level                     : 1,
  options                   : [],
  title                     : '',
  currentAnswer             : '',
  formAnswers               : {},
  photos                    : [],
  signatures                : [],
  videos                    : [],
  reviewMode                : false,
  isConditional             : false,
  isInternalQuestion        : false,
  onOpenAnswerFeedbackModal : () => {},
  onThumbnailClick          : () => {},
  onVideoThumbnailClick     : () => {},
  questionUuid              : '',
  isRejected                : false,
  rejectedQuestions         : {},
  isClientUser              : false,
  blockPhotos               : [],
  blockVideos               : []
};

export default QuestionItem;
