import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import {
  compose,
  defaultProps,
  setPropTypes,
  lifecycle,
  withStateHandlers,
  withHandlers
} from 'recompose';

import SvrResponsesTable from '../components/SvrResponsesTable';
import SvrResponsesPageToolbar from '../components/SvrResponsesPageToolbar';
import SearchFilter from '../../../components/SearchFilter';
import FileExportProgressModal from '../../modals/FileExportProgressModal';

import { fetchAutocomplete as _fetchAutocomplete, clearAutocomplete as _clearAutocomplete } from '../../client/ClientActions';

import {
  hideFileExportModal as _hideFileExportModal,
  exportToFile as _exportToFile
} from '../../file-export/FileExportActions';

import {
  getFileExportError,
  getFileExportLink,
  getShowFileExportModal
} from '../../file-export/FileExportReducer';

import {
  fetchSvrResponses as _fetchSvrResponses,
  updateSvrResponse as _updateSvrResponse,
  resetSvrResponse as _resetSvrResponse,
  deleteSvrResponse as _deleteSvrResponse,
  filterByAction as _filterByAction,
  transformData as _transformData,
  createSavedView as _createSavedView,
  createSavedRollup as _createSavedRollup,
  createSavedWidget as _createSavedWidget,
  setShowSearchFilter as _setShowSearchFilter,
  clearSvrResponses as _clearSvrResponses,
} from '../SvrResponsesActions';

import {
  getCurrentClient,
  getCurrentProject,
  isClientUser,
  getPrefilledFilters,
  getAutoComplete
} from '../../client/ClientReducer';

import {
  getSvrResponses,
  getTransformedData,
  getTableColumns,
  getFilterList,
  getShowSearchFilter
} from '../SvrResponsesReducer';

import {
  getSavedViews
} from '../../saved-views/SavedViewsReducer';

import {
  updateSavedView
} from '../../saved-views/SavedViewsActions';

import {
  getUserRole,
  getUserId,
  getEmail
} from '../../auth/AuthReducer';
import { deriveTenancyFilters } from '../../../utils/deriveTenancyFilters';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import moment   from 'moment';


const NAMESPACE = 'svrResponses';

const searchFilterStyle = {
  minHeight: '10rem',
  margin: '2rem 2rem 1rem 2rem',
  position: 'relative',
  backgroundColor: '#FFF',
  boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
};

const getStatusCode = label => {
  return R.compose(
    R.partialRight(parseInt, [10]),
    R.head,
    R.find(R.compose(R.equals(label), R.last)),
    R.toPairs
  )(window.WINSTON.statuses.savedView);
};

const DEFAULT_FIELDS = ['id', 'form_id', 'timestamp', 'event_id', 'svr_type', 'status', 'meta_mc_email', 'actual_checkin_time', 'actual_checkout_time', 'actual_duration_minutes', 'approved_by', 'checkin_status', 'checkout_status', 'meta_reviewed_by', 'meta_zone', 'meta_territory', 'actual_checkin_distance_km', 'device_os', 'device_os_version', 'device_model', 'device_app_version', 'last_submission_date', 'meta_has_call_out', 'meta_state', 'expected_checkin_longitude', 'actual_checkin_latitude', 'actual_checkin_longitude', 'privacy', 'meta_last_rejected_date'];
const DEFAULT_NUMBER_OF_DAYS_REPORT_FILTER = 7;
const mergeFieldsWithFilters = columns => {
  const mergedFields = R.compose(
    R.uniq,
    R.concat(DEFAULT_FIELDS),
    R.values,
    R.pluck('column')
  )(columns);
  return mergedFields;
};

const SvrResponsesPage = ({
  svrResponses,
  prefilledFilters,
  fetchAutocomplete,
  clearAutocomplete,
  autoComplete,
  transformedData,
  transformedDataDict,
  tableColumns,
  filterList,
  goToReviewPage,
  updateSvrResponse,
  resetSvrResponse,
  deleteSvrResponse,
  maybeFilterByAction,
  showSearchFilter,
  setShowSearchFilter,
  transformData,
  userRole,
  userId,
  currentClient,
  currentProject,
  savedView,
  title,
  description,
  createSavedView,
  createSavedWidget,
  createSavedRollup,
  namespace,
  userEmail,
  deleteSavedView,
  exportToFile,
  showFileExportModal,
  hideFileExportModal,
  fileExportLink,
  fileExportError,
  isClientUser,
  handleSelectData,
  selectedData,
  isFetchingData,
  syncFilters,
  checkin,
  showvalidate,
  closevalidate,
  showvalidate45days,
  closevalidate45days,
  showvalidatedoornumber12months,
  closevalidatedoornumber12months,
  showvalidatemaxdoors,
  closevalidatemaxdoors
}) => (
  <div>
    <Helmet>
      <title>SVR Responses</title>
    </Helmet>
    <SearchFilter
      data={svrResponses}
      prefilledFilters={prefilledFilters}
      fetchAutocomplete={fetchAutocomplete}
      clearAutocomplete={clearAutocomplete}
      autoComplete={autoComplete}
      transformedData={transformedData}
      tableColumns={!isClientUser() ? tableColumns : R.reject(tc => tc.label === "Review State" || tc.label === "Visibility", tableColumns)}
      filterByAction={maybeFilterByAction}
      filterList={filterList}
      syncFilters={syncFilters}
      namespace={namespace}
      style={searchFilterStyle}
      userRole={userRole}
      userId={userId}
      currentClient={currentClient}
      currentProject={currentProject}
      isClientUser={isClientUser()}
      allowSave={true}
      showWhen={showSearchFilter}
      onSaveAsView={createSavedView}
      onSaveAsWidget={createSavedWidget}
      onSaveAsRollup={createSavedRollup}
    />
    <SvrResponsesPageToolbar
      selectedData={selectedData}
      onExport={type => {
        exportToFile({
          title,
          type,
          transformedData: selectedData,
          namespace,
          tableColumns
        });
      }}
    />
    {
      showFileExportModal && (
        <FileExportProgressModal
          fileExportLink={fileExportLink}
          fileExportError={fileExportError}
          onApprove={hideFileExportModal}
        />
      )
    }
    <Dialog
      open={showvalidate}
      onClose={closevalidate}   
    >
      <DialogTitle></DialogTitle>
      <DialogContent style={{ justifyContent: 'space-between' }}>
        <Typography>Visit date range is required. Please select Visit Date Range</Typography>
      </DialogContent>
       <DialogActions style={{ justifyContent: 'flex-end' }}>
            {/* <Button onClick={onCancel} color="primary">
              Cancel
            </Button> */}

            <Button onClick={ closevalidate } color="primary">
              Ok
            </Button>
          </DialogActions> 
    </Dialog>

    <Dialog
      open={showvalidate45days}
      onClose={closevalidate45days}   
    >
      <DialogTitle></DialogTitle>
      <DialogContent style={{ justifyContent: 'space-between' }}>
        <Typography>You can only run this for maximum 45 days</Typography>
      </DialogContent>
       <DialogActions style={{ justifyContent: 'flex-end' }}>
            {/* <Button onClick={onCancel} color="primary">
              Cancel
            </Button> */}

            <Button  onClick={ closevalidate45days } color="primary">
              Ok
            </Button>
          </DialogActions> 
    </Dialog>

    <Dialog
      open={showvalidatedoornumber12months}
      onClose={closevalidatedoornumber12months}
    >
      <DialogTitle></DialogTitle>
      <DialogContent style={{ justifyContent: 'space-between' }}>
        <Typography>You can only run this for maximum 12 months</Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end' }}>
        {/* <Button onClick={onCancel} color="primary">
              Cancel
            </Button> */}

        <Button onClick={closevalidatedoornumber12months} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={showvalidatemaxdoors}
      onClose={closevalidatemaxdoors}
    >
      <DialogTitle></DialogTitle>
      <DialogContent style={{ justifyContent: 'space-between' }}>
        <Typography>You cannot have more than 5 doors when running this report for more than 45 days</Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end' }}>
        {/* <Button onClick={onCancel} color="primary">
              Cancel
            </Button> */}

        <Button onClick={closevalidatemaxdoors} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>


    <SvrResponsesTable
      transformedData={transformedData}
      transformedDataDict={transformedDataDict}
      data={svrResponses}
      title={title}
      description={description}
      tableColumns={!isClientUser() ? tableColumns : R.reject(tc => tc.label === "Visibility", tableColumns)} //{ tableColumns }
      savedView={savedView}
      deleteSavedView={deleteSavedView}
      onUpdate={updateSvrResponse}
      onReset={resetSvrResponse}
      onHardDelete={deleteSvrResponse}
      transformData={transformData}
      userRole={userRole}
      filterList={filterList}
      goToReviewPage={goToReviewPage}
      setShowSearchFilter={setShowSearchFilter}
      showSearchFilter={showSearchFilter}
      userEmail={userEmail}
      isClientUser={isClientUser()}
      handleSelectData={handleSelectData}
      namespace={namespace}
      checkin={checkin}
    />

    <Dialog
      open={isFetchingData}
      fullWidth={true}
    >
      <DialogTitle>Loading...</DialogTitle>
      <DialogContent style={{ justifyContent: 'space-between' }}>
        <Typography>Please wait</Typography>
      </DialogContent>
    </Dialog>
  </div>
);

const manageSvrResponsesFilterList = () => {
  // return {
  //   'Review State': {
  //     pathStr: 'metaJson.reviewState',
  //     path: ['metaJson', 'reviewState'],
  //     label: 'Review State',
  //     decorator: v => window.WINSTON.statuses.reviewState[v || 0],
  //     data: R.compose(
  //       values => R.filter(v => R.equals('Pending Review', v) || R.equals('Resubmitted', v), values),
  //       R.values
  //     )(window.WINSTON.statuses.reviewState)
  //   }
  // };

  var todayDate = new Date().toISOString().slice(0, 10);
  todayDate = todayDate.concat(" 23:59:59");  

  const now = moment();
  const pastdate = moment(now).subtract(DEFAULT_NUMBER_OF_DAYS_REPORT_FILTER, 'days').format("YYYY-MM-DD");
  
  var visitArray = [];
  var visitArrayClone = [];
  visitArray.push([pastdate, todayDate]);
  visitArrayClone.push(visitArray);

  return {
    'Visit Date': {
      pathStr: 'expectedCheckinTime',
      path: ['expectedCheckinTime'],
      label: 'Visit Date',
      //decorator: v => window.WINSTON.statuses.reviewState[v || 0],
      decorator: R.path(['WINSTON', 'tableColumns', 'storeVisit', "expectedCheckinTime", 'decorator'])(window),
      data: visitArrayClone
    }
  };
};

const maybeLoadInitialFilterListFromRouterProps = (state, props) => {
  let storedFilters = {};
  try {
    storedFilters = R.compose(
      maybeAppendDecorators,
      appendFilterType,
      JSON.parse
    )(window.localStorage.getItem(`persistedFilters:${props.namespace}`));
  } catch (e) {
    storedFilters = {};
  }

  var objFilterSize = Object.keys(storedFilters).length;

  if (objFilterSize === 0 && !isClientUser()) {
    return R.compose(
      maybeAppendDecorators,
      appendFilterType,
      R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
      R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
      R.when(R.isEmpty, R.always(props.initialFilterList || {})),
      manageSvrResponsesFilterList,
      R.mergeDeepLeft(storedFilters),
      getFilterList(props.namespace)
    )(state);
  }
  else if (objFilterSize === 1 && storedFilters.hasOwnProperty('Client Name') /*&& !isClientUser()*/) {
    return R.compose(
      maybeAppendDecorators,
      appendFilterType,
      R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
      R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
      R.when(R.isEmpty, R.always(props.initialFilterList || {})),
      manageSvrResponsesFilterList,
      R.mergeDeepLeft(storedFilters),
      getFilterList(props.namespace)
    )(state);
  }
  else {
  return R.compose(
    maybeAppendDecorators,
    appendFilterType,
    R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
    R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
    R.when(R.isEmpty, R.always(props.initialFilterList || {})),
    R.mergeDeepLeft(storedFilters),
    getFilterList(props.namespace)
  )(state);
  }

  // return R.compose(
  //   maybeAppendDecorators,
  //   appendFilterType,
  //   R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
  //   R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
  //   R.when(R.isEmpty, R.always(props.initialFilterList || {})),
  //   R.mergeDeepLeft(storedFilters),
  //   getFilterList(props.namespace)
  // )(state);
};

const maybeDispatchFetchSvrResponses = (dispatch, namespace, filters, fields = [], sortandcolors) => {
  const activeFilters = R.filter(R.compose(R.not, R.isEmpty, R.prop('data')))(filters);
  if (Object.keys(activeFilters).length) {
    return dispatch(_fetchSvrResponses(namespace, filters, fields, true, sortandcolors));
  }
  dispatch(_clearSvrResponses(namespace));
  return Promise.resolve();
};

function _goToReviewPage(props, id) {
  return (dispatch, getState) => {
    window.localStorage.setItem("displayUnansweredSections", 'NO');
    if (!R.isNil(window.localStorage.getItem('dashboardId')) && !R.isEmpty(window.localStorage.getItem('dashboardId'))) {
      window.localStorage.removeItem('dashboardId')
    }
    const state = getState();
    const currentIds = R.compose(R.map(d => { return { id: d.id }; }), R.pathOr([], [NAMESPACE, props.namespace, 'transformedData']))(state);
    props.history.push(`/svr-response/${id}`, { ids: currentIds });
  };
}

const mapStateToProps = (state, props) => ({
  svrResponses: getSvrResponses(state, props.namespace || NAMESPACE),
  transformedData: getTransformedData(state, props.namespace || NAMESPACE),
  transformedDataDict: R.indexBy(R.prop('id'), getTransformedData(state, props.namespace || NAMESPACE) || []),
  tableColumns: getTableColumns(props.namespace || NAMESPACE)(state),
  userRole: getUserRole(state),
  userId: getUserId(state),
  userEmail: getEmail(state),
  currentClient: getCurrentClient(state),
  currentProject: getCurrentProject(state),
  showSearchFilter: getShowSearchFilter(state),
  savedViews: getSavedViews(state),
  filterList: maybeLoadInitialFilterListFromRouterProps(state, props),
  showFileExportModal: getShowFileExportModal(state),
  fileExportLink: getFileExportLink(state),
  fileExportError: getFileExportError(state),
  prefilledFilters: getPrefilledFilters(state),
  autoComplete: getAutoComplete(state),
  isClientUser
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  fetchSvrResponses: (namespace, filters, fields, groupMultiDaySvrs, sortandcolors) => dispatch(_fetchSvrResponses(namespace, filters, fields, groupMultiDaySvrs, sortandcolors)),
  updateSvrResponse: (namespace, svrResponseId, payload) => dispatch(_updateSvrResponse(namespace, svrResponseId, payload)),
  resetSvrResponse: (namespace, svrResponseId) => dispatch(_resetSvrResponse(namespace, svrResponseId)),
  deleteSvrResponse: id => dispatch(_deleteSvrResponse(id)),
  goToReviewPage: id => dispatch(_goToReviewPage(props, id)),
  persistFilters: (namespace, filters) => dispatch(_filterByAction(namespace, filters)),
  setShowSearchFilter: bool => dispatch(_setShowSearchFilter(bool)),
  createSavedView: data => dispatch(_createSavedView(data)),
  createSavedWidget: data => dispatch(_createSavedWidget(data)),
  createSavedRollup: data => dispatch(_createSavedRollup(data)),
  deleteSavedView: ({ id }) => {
    dispatch(updateSavedView(id, { status: getStatusCode('Deleted') })).then(() => {
      props.history.push('/manage');
    });
  },
  hideFileExportModal: payload => dispatch(_hideFileExportModal(payload)),
  exportToFile: payload => dispatch(_exportToFile(payload)),
  fetchAutocomplete: payload => dispatch(_fetchAutocomplete(payload)),
  clearAutocomplete: () => dispatch(_clearAutocomplete()),
});

const maybeAppendDecorators = R.map((filter = {}) => {
  const decorator = R.path(['WINSTON', 'tableColumns', 'storeVisit', filter.pathStr, 'decorator'])(window);
  if (R.is(Function, decorator)) {
    return R.assoc('decorator', decorator, filter);
  } else {
    return filter;
  }
});

const appendFilterType = R.map((filter = {}) => {
  const type = R.path(['WINSTON', 'tableColumns', 'storeVisit', filter.pathStr, 'type'])(window);
  return R.assoc('type', type, filter);
});

const withSvrResponses = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({
      selectedData = [],
      isFetchingData = false,
    }) => ({
      selectedData,
      isFetchingData
    }),
    ({
      handleSelectData: (__, { transformedData }) => selectedData => {
        if (selectedData.length > 0) {
          return {
            selectedData: R.compose(
              R.filter(d => R.contains(d.eventId, selectedData))
            )(transformedData)
          };
        }

        return { selectedData };
      },
      setFetchingData: () => fetching => ({
        isFetchingData: fetching
      }),
      setOpenData: () =>  isbool => ({
        showvalidate: isbool
      })
      ,
      closevalidate: () => () =>  ({
        showvalidate: false
      }),

      setOpenData45days: () =>  isbool => ({
        showvalidate45days: isbool
      })
      ,
      closevalidate45days: () => () =>  ({
        showvalidate45days: false
      }), 
      setOpenDatadoornumber12months: () => isbool => ({
        showvalidatedoornumber12months: isbool
      })
      ,
      closevalidatedoornumber12months: () => () => ({
        showvalidatedoornumber12months: false
      }),
      setOpenDatamaxdoor: () => isbool => ({
        showvalidatemaxdoors: isbool
      })
      ,
      closevalidatemaxdoors: () => () => ({
        showvalidatemaxdoors: false
      }),     
    }),
  ),
  withHandlers({
    filterByAction: props => (namespace, filters) => {
      Promise.resolve(props.setFetchingData(true))
        .then(() => props.dispatch(_fetchSvrResponses(namespace, filters)))
        .then(() => props.dispatch(_filterByAction(namespace, filters)))
        .then(() => props.setFetchingData(false));
    },
    maybeFilterByAction: props => (namespace, filters, sortandcolors) => {
      Promise.resolve(props.setFetchingData(true))
        .then(() => maybeDispatchFetchSvrResponses(props.dispatch, namespace, filters, mergeFieldsWithFilters(props.tableColumns), sortandcolors))
        .then(() => props.dispatch(_filterByAction(namespace, filters)))
        .then(() => props.setFetchingData(false));
    },
    validate: props => (namespace, filters) => {
        props.setOpenData(true);
    },    
    validate45days: props => (namespace, filters) => {
      props.setOpenData45days(true);
    },
    validatedoornumber12months: props => (namespace, filters) => {
      props.setOpenDatadoornumber12months(true);
    },
    validatemaxdoornumber: props => (namespace, filters) => {
      props.setOpenDatamaxdoor(true);
    }, 
    syncFilters: props => (namespace, filters) => props.dispatch(_filterByAction(namespace, filters))
    ,
    transformData: props => (orderBy, order, sortType, filterList, data, namespace) => props.dispatch(_transformData(orderBy, order, sortType, filterList, data, namespace, props.tableColumns)),
  }),
  defaultProps({
    svrResponses: [],
    savedView: {},
    savedViews: [],
    transformedData: [],
    tableColumns: [],
    showSearchFilter: false,
    initialFilterList: {},
    namespace: NAMESPACE,
    isClientUser: () => { },
    checkin: false,
    showvalidate: false,
    showvalidate45days: false,
    showvalidatedoornumber12months: false,
    showvalidatemaxdoors : false
  }),
  setPropTypes({
    svrResponses: PropTypes.array,
    savedViews: PropTypes.array,
    savedView: PropTypes.object,
    transformedData: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
    tableColumns: PropTypes.array,
    updateSvrResponse: PropTypes.func.isRequired,
    resetSvrResponse: PropTypes.func.isRequired,
    deleteSvrResponse: PropTypes.func.isRequired,
    createSavedView: PropTypes.func,
    createSavedWidget: PropTypes.func,
    createSavedRollup: PropTypes.func,
    showSearchFiler: PropTypes.bool,
    setShowSearchFilter: PropTypes.func,
    filterList: PropTypes.object.isRequired,
    initialFilterList: PropTypes.object,
    userRole: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    isClientUser: PropTypes.func,
    checkin: PropTypes.bool,
    showvalidate: PropTypes.bool,
    closevalidate: PropTypes.func,
    showvalidate45days: PropTypes.bool,
    closevalidate45days: PropTypes.func,
    showvalidatedoornumber12months: PropTypes.bool,    
    closevalidatedoornumber12months: PropTypes.func,
    showvalidatemaxdoors: PropTypes.bool,  
    closevalidatemaxdoors: PropTypes.func,      
  }),
  lifecycle({
    componentDidMount() {
      this.props.setShowSearchFilter(true);
      var isvalidated = false;   
      var isvalidated45days = false;
      var count45days = 0;
      var isValidated12months = false;
      var isShowDoornumber = false;
      var dcountMonths = 0;
      var doornumbermaxlength = 0;

    Object.entries(this.props.filterList).forEach(function([key, val], idx, arr){
      // console.log("Key:", key);
      // console.log("Index:", idx);
      // console.log("Original Array:", arr);
      // console.log("Value:", val);
      // console.log("val.data:", val.data);
      // console.log("val.pathStr:", val.pathStr);
      if(val.pathStr === "expectedCheckinTime" && val.data.length === 0)
      {
          isvalidated = true;              
      }
      else if (val.pathStr === "metaJson.doorNumber" && val.data.length > 0) {
        isShowDoornumber = true;
        doornumbermaxlength = val.data.length;
        dcountMonths = count45days / 30;
        if (dcountMonths > 12) {
          isValidated12months = true;
        }
      }
      else if(val.pathStr === "expectedCheckinTime" && val.data.length > 0)
      {
        //debugger;
        for(var i = 0; i < val.data.length; i++)
          {
               debugger;
               var todate = val.data[i][1];
               if(R.isNil(todate))
               {
                  var Dfilter = val.data[i][0];
                  for(var j = 0; j < Dfilter.length - 1; j++)
                  {
                     var dtodate = Dfilter[1];
                     var dfromdate = Dfilter[0];
  
                     const ddaysdiff = Math.round(moment(dtodate).diff(dfromdate, 'days', true));
                     count45days = count45days + ddaysdiff;
                  }
               }
               else
               {
               var fromdate = val.data[i][0];
               const daysdiff = Math.round(moment(todate).diff(fromdate, 'days', true));
               count45days = count45days + daysdiff;
               }
          }

        if(count45days  > 45)
        {
           isvalidated45days = true;
        }        
      } 
  });

    if (isvalidated) {     
      //alert("Visit date range is required. Please select Visit Date Range");
      this.props.validate(this.props.namespace, this.props.filterList || {});
      return;
    }
    else if (isvalidated45days) {
      //alert("You can only run this for maximum 45 days");
      if (isShowDoornumber) {
        if (isValidated12months) {
          this.props.validatedoornumber12months(this.props.namespace, this.props.filterList || {});
          return;
        }
        else if (doornumbermaxlength > 5) {
          this.props.validatemaxdoornumber(this.props.namespace, this.props.filterList || {});
          return;
        }
        else {
          this.props.maybeFilterByAction(this.props.namespace, this.props.filterList || {});
        }
      }
      else {
        this.props.validate45days(this.props.namespace, this.props.filterList || {});
        return;
      }
    }
    else {
      this.props.maybeFilterByAction(this.props.namespace, this.props.filterList || {});
    }
    },
    componentDidUpdate(prevProps) {
      const savedViewUpdated = !R.equals(prevProps.savedView, this.props.savedView),
        filterListUpdated = !R.equals(prevProps.filterList, this.props.filterList),
        initialFilterListUpdated = !R.equals(prevProps.initialFilterList, this.props.initialFilterList);

      const filters = this.props.filterList || {};

      if (savedViewUpdated || initialFilterListUpdated) {

        var isvalidated = false;   
        var isvalidated45days = false;
        var count45days = 0;
        var isValidated12months = false;
        var isShowDoornumber = false;
        var dcountMonths = 0;
        var doornumbermaxlength = 0;

        Object.entries(this.props.filterList).forEach(function([key, val], idx, arr){
          // console.log("Key:", key);
          // console.log("Index:", idx);
          // console.log("Original Array:", arr);
          // console.log("Value:", val);
          // console.log("val.data:", val.data);
          // console.log("val.pathStr:", val.pathStr);
          if(val.pathStr === "expectedCheckinTime" && val.data.length === 0)
          {
              isvalidated = true;              
          }
          else if (val.pathStr === "metaJson.doorNumber" && val.data.length > 0) {
            isShowDoornumber = true;
            dcountMonths = count45days / 30;
            doornumbermaxlength = val.data.length;
            if (dcountMonths > 12) {
              isValidated12months = true;
            }
          }
          else if(val.pathStr === "expectedCheckinTime" && val.data.length > 0)
          {
            //debugger;
            for(var i = 0; i < val.data.length; i++)
              {
                   debugger;
                   var todate = val.data[i][1];
                   if(R.isNil(todate))
                   {
                      var Dfilter = val.data[i][0];
                      for(var j = 0; j < Dfilter.length - 1; j++)
                      {
                         var dtodate = Dfilter[1];
                         var dfromdate = Dfilter[0];
      
                         const ddaysdiff = Math.round(moment(dtodate).diff(dfromdate, 'days', true));
                         count45days = count45days + ddaysdiff;
                      }
                   }
                   else
                   {
                   var fromdate = val.data[i][0];
                   const daysdiff = Math.round(moment(todate).diff(fromdate, 'days', true));
                   count45days = count45days + daysdiff;
                   }
              }
    
            if(count45days  > 45)
            {
               isvalidated45days = true;
            }        
          } 
      });
    
        if (isvalidated) {
          // this.setState({
          //   showErrorModal: true,
          //   errorModalTitle: "Visit date range is required. Please select Visit Date Range"
          // });
          //alert("Visit date range is required. Please select Visit Date Range");
          return;
        }
        else if (isvalidated45days) {
          // this.setState({
          //   showErrorModal: true,
          //   errorModalTitle: "You can only run this for maximum 45 days"
          // });
          //alert("You can only run this for maximum 45 days");
          if (isShowDoornumber) {
            if (isValidated12months) {
              //this.props.validatedoornumber12months(this.props.namespace, this.props.filterList || {});
              return;
            }
            else if (doornumbermaxlength > 5) {
              //this.props.validatemaxdoornumber(this.props.namespace, this.props.filterList || {});
              return;
            }
            else {
              this.props.maybeFilterByAction(this.props.namespace, this.props.filterList || {});
            }
          }
          else {
            return;
          }
        }
        else {
          this.props.maybeFilterByAction(this.props.namespace, this.props.filterList || {});
        }
        this.props.maybeFilterByAction(this.props.namespace, filters);
      }

      else if (filterListUpdated) {
        this.props.persistFilters(this.props.namespace, filters);
      }
    }
  })
);

export default withSvrResponses(SvrResponsesPage);
